import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './layouts/common/header/header.component';
import { MasterComponent } from './layouts/master/master.component';
import { AuthComponent } from './layouts/auth/auth.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UiService } from './services/ui/ui.service';
import { TranslateModule } from '@ngx-translate/core';
import { ngxUiLoaderConfig, translateLoader, toastrConfig } from './common/global';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrModule } from 'ngx-toastr';
import { HttpConfigInterceptor } from './common/http-config-interceptor';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MasterComponent,
    AuthComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NgbTooltipModule,
    TranslateModule.forRoot(translateLoader),
    NgbModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    ToastrModule.forRoot(toastrConfig)
  ],
  providers: [UiService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor, multi: true
    },
    NgxUiLoaderService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

