import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseModel } from '../../models/base-model';
import { ApiResponse } from '../../common/api-response';
import { environment } from '../../../environments/environment';
import { PaginationResponse } from 'src/app/models/pagination';

@Injectable({
  providedIn: 'root'
})

/**
 * This is the base service
 * inherited to BaseModel
 */
export class ApiService<T extends BaseModel> {

  private apiServerUrl = environment.apiServerUrl + environment.apiVersion; // get the server apiServerUrl of current environment
  private fileServerUrl = environment.fileServerUrl + environment.fileVersion; // get the server fileServerUrl of current environment

  constructor(private httpClient: HttpClient) { }

  /**
   * create T object and returns ApiResponse
   */
  create(endpoint: string, item: T): Observable<ApiResponse> {
    return this.httpClient
      .post<ApiResponse>(`${this.apiServerUrl}/${endpoint}`, item, { observe: 'response' as 'body' });
  }

  /**
   * update T object and returns ApiResponse
   */
  update(endpoint: string, item: T): Observable<ApiResponse> {
    return this.httpClient
      .put<ApiResponse>(`${this.apiServerUrl}/${endpoint}/${item._id}`,
        item, { observe: 'response' as 'body' });
  }

  /**
   * get any object without mapping to T
   */
  get(endpoint: string): Observable<any> {
    return this.httpClient
      .get(`${this.apiServerUrl}/${endpoint}`);
  }

  /**
   * get single object
   */
  getSingle(endpoint: string): Observable<T> {
    return this.httpClient
      .get(`${this.apiServerUrl}/${endpoint}`)
      .pipe(map(data => data as T));
  }

  /**
   * get object list
   */
  getList(endpoint: string): Observable<T[]> {
    return this.httpClient
      .get(`${this.apiServerUrl}/${endpoint}`)
      .pipe(map(data => data as T[]));
  }

  /**
   * get pagination object with list of data (any[])
   * @param endpoint | api path
   * @param queryString | apiServerUrl parameters for pagination
   */
  getPagination(endpoint: string, queryString?: URLSearchParams): Observable<PaginationResponse> {
    return this.httpClient
      .get(`${this.apiServerUrl}/${endpoint}${queryString ? '?' + queryString : ''}`)
      .pipe(map(data => data as PaginationResponse));
  }

  /**
   * delete object with id and returns ApiResponse
   */
  delete(endpoint: string, id: string): Observable<ApiResponse> {
    return this.httpClient
      .delete<ApiResponse>(`${this.apiServerUrl}/${endpoint}/${id}`, { observe: 'response' as 'body' });
  }

  /**
   * create T object and returns ApiResponse
   */
  createList(endpoint: string, list: T[]): Observable<ApiResponse> {
    return this.httpClient
      .post<ApiResponse>(`${this.apiServerUrl}/${endpoint}`, list, { observe: 'response' as 'body' });
  }

  /**
   * upload files and returns ApiResponse
   */
  upload(endpoint: string, formData: FormData): Observable<HttpEvent<ApiResponse>> {
    return this.httpClient
      .post<ApiResponse>(`${this.fileServerUrl}/${endpoint}`, formData, { reportProgress: false, observe: 'events' });
  }

   /**
   * download excel files
   */
  downloadExcel(endpoint: string, filters: any, fileName: string) {
    const url = `${this.apiServerUrl}/${endpoint}`;
    this.httpClient.post(url, filters, { responseType: 'blob' }).subscribe(
      (response) => {
        const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName || 'report.xlsx';
        link.click();
  
        // Cleanup: Revoke object URL to free memory
        setTimeout(() => window.URL.revokeObjectURL(link.href), 100);
      },
      (error) => {
        console.error('Error downloading Excel:', error);
      }
    );
  }
  
}

